<template>
  <v-container class="d-flex">
    <v-card v-if="!hasAnswered" style="margin-top: 36px;" max-width="400" class="mx-auto">
      <v-card-title>
        {{`Are you sure you would like to unsubscribe from receiving ${$route.query.frequency} emails for ${decodeURIComponent($route.query.account)}'s account?`}}
      </v-card-title>
      <v-card-actions>
        <v-btn @click="$router.go(-1)">
          No
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="unsubscribe">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-else>
      <div v-if="successfulResponse" class="margin">
        {{ `You (${decodeURIComponent(recipient)}) have been unsubscribed from having ${frequency} screenshot emails sent from Truple account ${decodeURIComponent(accountId)}`}}
      </div>
      <div v-else>
        <span>Issue unsubscribing your email on that account, please try again later.</span>
      </div>
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'dailyEmailUnsubscribe',
    data () {
      return {
        loading: false,
        recipient: null,
        accountId: null,
        successfulResponse: false,
        hasAnswered: false
      }
    },
    methods: {
      loadAndValidateQueryParameters() {
        this.recipient = this.$route.query.recipient;
        this.accountId = this.$route.query.account;
        this.frequency = this.$route.query.frequency

      },
      async unsubscribe() {

        try {
          await this.api.dailyEmailUnsubscribe({
            recipient: this.$route.query.recipient,
            accountId: this.$route.query.account,
            frequency: this.$route.query.frequency
            // block: undefined  //just leave it off
          })
          this.successfulResponse = true
        } catch(err) {
          // eslint-disable-next-line no-console
          console.log(err)
          alert('Error unsubscribing.  Please try again.  If it persists, contact support@truple.io.')
        } finally {
          this.loading = false
          this.hasAnswered = true
        }
      }
    },
    beforeMount() {
      this.api.setUnauthedCredentials()
      this.loadAndValidateQueryParameters()
    },
    mounted() {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .margin {
    margin: 32px 32px 32px 32px;
  }
</style>
